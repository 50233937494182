<template>
   <v-dialog v-model="show" max-width="290">
      <v-card>
         <v-card-title>{{ message }}</v-card-title>
         <v-card-text style="background-color: white; padding: 0">
            <VueSignaturePad ref="signaturePad" height="100px" />
            <v-btn icon small light @click="clear" class="ma-1"><v-icon small>mdi-eraser</v-icon></v-btn>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="error" text @click="dialogResult_OK">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
export default {
   props: {
      message: String,
   },
   data: () => {
      return {
         show: false,
      };
   },
   methods: {
      clear() {
         this.$refs.signaturePad.clearSignature();
      },
      dialogResult_OK: function () {
         const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
         this.show = false;
         this.$emit("result_ok", { sign: data });
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show: function () {
         this.show = true;
         setTimeout(() => {
            this.$refs.signaturePad.resizeCanvas();
         }, 250);
      },
   },
};
</script>
