<template>
   <fieldset class="d-flex">
      <legend>{{ label }}</legend>
      <p v-if="src.trim().length == 0">
         <v-icon class="icon">{{ icon }}</v-icon>
         {{ value }}
      </p>
      <img v-if="src.trim().length > 0" :src="src" :width="width" :height="height" class="mx-auto" />
   </fieldset>
</template>

<script>
export default {
   props: {
      value: {
         type: String,
         default: "value",
      },
      label: {
         type: String,
         default: "label",
      },
      icon: {
         type: String,
         default: "",
      },
      src: {
         type: String,
         default: "",
      },
      height: {
         type: String,
         default: "100px",
      },
      width: {
         type: String,
         default: "100px",
      },
   },
};
</script>

<style scoped>
fieldset {
   border: 1px solid rgb(187, 187, 187);
   border-radius: 3px;
   margin: 10px 0;
   padding: 12px 8px 16px 12px;
}
legend {
   padding-right: 3px;
   padding-left: 3px;
   font-size: small;
   font-weight: bold;
}
p {
   margin: 0;
   font-size: large;
}
.icon {
   display: inline-block;
   padding-bottom: 5px;
}
</style>
