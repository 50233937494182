<template>
   <div>
      <Notification type="error" message="Error" ref="error_noti" />
      <Sign message="Confirmar reporte" ref="signature" @result_ok="sign" />
      <v-toolbar :color="isCancelado">
         Reporte {{ folio }}
         <div v-if="cancelado == 'S'">
            <div style="width: 8px; display: inline-block"></div>
            CANCELADO
         </div>
         <v-spacer></v-spacer>
         <div v-if="cancelado != 'S'" class="d-flex">
            <div v-if="signable">
               <v-btn color="primary" disabled v-if="Firmado" class="d-none d-sm-flex mr-1"><v-icon small left>mdi-check-bold</v-icon>Firmado</v-btn>
               <v-btn color="accent" disabled v-if="Firmado" class="d-flex d-sm-none mr-1" icon><v-icon small>mdi-check-bold</v-icon></v-btn>
               <v-btn color="primary" :loading="signloading" @click="$refs.signature.Show()" v-if="!Firmado" class="d-none d-sm-flex mr-1"
                  ><v-icon small left>mdi-draw-pen</v-icon>Firmar</v-btn
               >
               <v-btn color="accent" :loading="signloading" @click="$refs.signature.Show()" v-if="!Firmado" class="d-flex d-sm-none mr-1" icon
                  ><v-icon small>mdi-draw-pen</v-icon></v-btn
               >
            </div>
            <v-btn color="primary" class="d-none d-sm-flex ml-1" :loading="isloading" @click="generateReport"
               ><v-icon small left>mdi-file-pdf-box</v-icon>Descargar</v-btn
            >
            <v-btn color="accent" class="d-flex d-sm-none ml-1" :loading="isloading" @click="generateReport" icon
               ><v-icon small>mdi-file-pdf-box</v-icon></v-btn
            >
         </div>
      </v-toolbar>
      <div v-if="isReporte" class="card-pdf-container mx-auto my-4">
         <v-card light color="white" style="min-width: 900px" class="pdf-container">
            <vue-html2pdf
               :show-layout="true"
               :float-layout="false"
               :enable-download="true"
               :preview-modal="false"
               :pdf-quality="2"
               :manual-pagination="true"
               :html-to-pdf-options="htmlToPdfOptions"
               pdf-format="letter"
               pdf-orientation="portrait"
               pdf-content-width="100%"
               @progress="onProgress($event)"
               @hasStartedGeneration="hasStartedGeneration()"
               @hasGenerated="hasGenerated($event)"
               ref="html2Pdf"
            >
               <section slot="pdf-content">
                  <section class="pdf-item">
                     <img :src="header" class="reporte-header" />
                     <v-row>
                        <v-col></v-col>
                        <v-col cols="2" style="font-size: 15px;">
                           <span style="font-weight: bold;"> Folio: </span>
                           {{ folio }}
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="1"></v-col>
                        <v-col class="d-flex flex-column mr-2" cols="6">
                           <Field :value="empresa" label="Nombre de la empresa" />
                           <Field :value="descripcion" label="Descripción" />
                        </v-col>
                        <v-col class="d-flex flex-column">
                           <Field :value="atendio" label="Atendio" />
                           <Field :value="fecha_hora" label="Fecha y Hora" />
                           <Field :value="importe.toString()" label="Importe" :icon="type_cobro_icon" />
                        </v-col>
                        <v-col cols="1"></v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                           <Field :value="notas" label="Comentarios" />
                        </v-col>
                        <v-col cols="1"></v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                           <Field :value="solicito" label="Solicitó" />
                        </v-col>
                        <v-col cols="1"></v-col>
                     </v-row>
                     <v-row v-if="sign_img.trim().length > 0">
                        <v-col cols="1"></v-col>
                        <v-col>
                           <Field :src="sign_img" label="Firma" width="290px" />
                        </v-col>
                        <v-col cols="1"></v-col>
                     </v-row>
                  </section>
                  <v-row>
                     <v-col v-for="(item, index) in imgs" :key="index" cols="12">
                        <div class="html2pdf__page-break" />
                        <img :src="item.img" class="reporte-img" />
                     </v-col>
                  </v-row>
               </section>
            </vue-html2pdf>
         </v-card>
      </div>
   </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Field from "./Reporte/Field.vue";
import Notification from "../Notification.vue";
import Sign from "./Reporte/Sign.vue";

export default {
   components: {
      VueHtml2pdf,
      Field,
      Sign,
      Notification,
   },
   props: {
      init: {
         type: String,
         default: "",
      },
      signable: {
         type: Boolean,
         default: true,
      },
   },
   data: () => {
      return {
         folio: "",
         header: "",
         empresa: "",
         atendio: "",
         descripcion: "",
         fecha_hora: "",
         importe: "",
         tipo_cobro: "",
         notas: "",
         solicito: "",
         imgs: "",
         firmado: "",
         sign_img: "",
         token: "",
         cancelado: "N",
         isloading: false,
         signloading: false,
         htmlToPdfOptions: {
            html2canvas: {
               useCORS: true,
            },
         },
      };
   },
   computed: {
      type_cobro_icon: function () {
         return this.tipo_cobro == "H" ? "mdi-clock-outline" : "mdi-currency-usd";
      },
      isReporte: function () {
         return this.folio.toString().trim().length > 0 ? true : false;
      },
      Firmado: function () {
         return this.firmado == "S" ? true : false;
      },
      isCancelado: function () {
         if (this.cancelado == "S") {
            return "error";
         } else {
            return "secondary";
         }
      },
   },
   mounted: function () {
      this.token = this.init;
      this.initialize();
   },
   methods: {
      async initialize() {
         try {
            if (this.token) {
               let res = await this.$store.dispatch("getReporte", {
                  token: this.token,
               });
               if (res == true) {
                  this.folio = this.$store.state.reporte.folio;
                  this.header = this.$store.state.reporte.header;
                  this.empresa = this.$store.state.reporte.empresa;
                  this.atendio = this.$store.state.reporte.atendio;
                  this.descripcion = this.$store.state.reporte.descripcion;
                  this.fecha_hora = this.$store.state.reporte.fecha_hora;
                  this.importe = this.$store.state.reporte.importe;
                  this.tipo_cobro = this.$store.state.reporte.tipo_cobro;
                  this.notas = this.$store.state.reporte.notas;
                  this.solicito = this.$store.state.reporte.solicito;
                  this.imgs = this.$store.state.reporte.imgs;
                  this.firmado = this.$store.state.reporte.firmado;
                  this.cancelado = this.$store.state.reporte.cancelado;
               } else {
                  throw "Error en peticion";
               }
            }
         } catch (error) {
            this.$refs.error_noti.Show();
         }
      },
      async sign(e) {
         try {
            if (this.token) {
               this.sign_img = e.sign;
               this.signloading = true;
               let res = await this.$store.dispatch("signReporte", {
                  token: this.token,
               });
               if (res == true) {
                  await this.initialize();
               } else {
                  throw "Error en peticion";
               }
            } else {
               throw "Error en parametros";
            }
         } catch (error) {
            this.$refs.error_noti.Show();
         } finally {
            this.signloading = false;
         }
      },
      generateReport() {
         this.isloading = true;
         this.$refs.html2Pdf.generatePdf();
      },
      onProgress(e) {
         if (e == 100) {
            this.isloading = false;
         }
      },
      hasStartedGeneration(e) {},
      hasGenerated(e) {},
      show(t) {
         this.token = t;
         this.initialize();
      },
   },
};
</script>

<style scoped>
.reporte-header {
   width: 100%;
}
.reporte-img {
   width: 100%;
}
.card-pdf-container {
   overflow-y: hidden;
   overflow-x: scroll;
   max-width: 910px;
}
.pdf-container {
   min-width: 900px;
   max-width: 900px;
   overflow: hidden;
}
</style>
